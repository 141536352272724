<template>
  <div id="kids-management-page">
    <AppContent
      class="management-content"
      :isFullPage="true"
      :isFluid="false"
      :isContentContainer="true"
      :withSidebar="true">
      <b-row class="justify-content-center mt-5">
        <b-col
          class="content-col"
          cols="12"
          lg="7"
          md="7">
          <UserProfiles />
        </b-col>
        <!-- <b-col
          class="content-col"
          cols="12"
          lg="6"
          md="6">
          <Subscription :showChangePin="true" />
        </b-col>
        <b-col
          class="content-col"
          cols="12"
          lg="6"
          md="6"
          v-if="subscription.class_name !== 'free'">
          <UserProfiles />
        </b-col> -->
      </b-row>
    </AppContent>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    head : {
      title : {
        inner      : 'Manage Profiles',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    components : {
      'AppContent'   : () => import('@/components/layout/AppContent'),
      'UserProfiles' : () => import('./UserProfiles'),

      // 'Subscription' : () => import('@/components/subscription/Info'),
    },
    computed : {
      ...mapGetters({
        subscription : 'subscription/subscription',
        users        : 'subscription/userProfiles',
      }),
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #kids-management-page {
    min-height: 100vh;
    background-image: $profile-bg;
    background-attachment: scroll;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content-col {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (max-width: $xs-max) {
    .management-content {
      min-height: calc(100vh - 5rem);
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .management-content {
      min-height: calc(100vh - 8rem);
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .management-content {
      min-height: calc(100vh - 10rem);
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    #kids-management-page {
      padding-left: calc(#{$sidebar-width} + 2em);
    }
    .management-content {
      min-height: calc(100vh - 3rem);
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    
  }

  @media only screen and (min-width: $special) {
    #kids-management-page {
      padding-left: $sidebar-width;
    }
    .management-content {
      min-height: calc(100vh - 3rem);
    }
  }

  @media only screen and (min-width: $xl2-min) {
    #kids-management-page {
      padding-left: 8rem;
    }
    .management-content {
      min-height: calc(100vh - 3rem);
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    #kids-management-page {
      padding-left: calc(#{$sidebar-width + 2em});
    }
    .management-content {
      min-height: calc(100vh - 4rem);
    }
  }
</style>